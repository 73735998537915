import { LocaleMap } from '@summer-health/types/locale';
import { useTranslation } from 'next-i18next';
import { InterstitialPageQueryGQL } from '../../queries/contentful/interstitial-page-query.gql';
import { useContentfulQuery } from '../../queries/contentful/use-contentful-query';

type WithInterstitialPageContentProps = {
  contentId: string;
};

type HOCComponentProps<TProps> = Omit<
  TProps & WithInterstitialPageContentProps,
  'content' | 'isLoading'
>;

export function WithInterstitialPageContent<TProps>(
  Component: React.ComponentType<TProps>,
): (props: HOCComponentProps<TProps>) => JSX.Element {
  const ComponentRender = (
    props: TProps & WithInterstitialPageContentProps,
  ): JSX.Element => {
    const { contentId } = props;
    const { i18n } = useTranslation();

    const { data, isLoading } = useContentfulQuery(
      InterstitialPageQueryGQL(contentId, LocaleMap[i18n.language]),
    );

    return (
      <Component
        {...props}
        content={data?.interstitialPage}
        isLoading={isLoading}
      />
    );
  };

  ComponentRender.displayName = `WithInterstitialPageContent(${Component.displayName})`;

  return ComponentRender;
}
