import { ContentfulInterstitialFragment } from './interstitial-fragment';

export const InterstitialPageQueryGQL = (
  id: string,
  locale: string = 'en-US',
): string => `
  query {
    interstitialPage(id: "${id}", locale:"${locale}") {
      ${ContentfulInterstitialFragment}
    }
  }

`;
