import { getSession } from '@auth0/nextjs-auth0';
import { UserStatus } from '@summer-health/types/user';
import { PromotionBanner, SignupHeader } from '@summer-health/ui/care-signup';
import { GetServerSidePropsResult } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { Layout } from '../../components/shared/layout';
import { WelcomePage } from '../../components/signup/welcome-page';

export default function Welcome(): JSX.Element {
  return <WelcomePage />;
}

export const getServerSideProps = async (
  ctx,
): Promise<GetServerSidePropsResult<object>> => {
  const session = await getSession(ctx.req, ctx.res);
  if (session) {
    if (session?.user?.status === UserStatus.Registered) {
      return {
        redirect: {
          destination: `/home`,
          permanent: false,
        },
      };
    }
  }

  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, ['common'])),
    },
  };
};

Welcome.getLayout = function getLayout(
  page: JSX.Element,
  pageProps,
): JSX.Element {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  const promotionCode = router.query?.promotion_code?.toString();

  const header = (
    <>
      <PromotionBanner promotionCode={promotionCode} />
      <SignupHeader />
    </>
  );
  return (
    <Layout header={header} {...pageProps}>
      {page}
    </Layout>
  );
};
